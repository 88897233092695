import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import {MainList} from '../components/utils.js'

const Main = ({ data }) => {
	const totalCount = data.projects.totalCount;
	const totalTalk = data.talks.totalCount;
	return (
		<Layout location={'main'}>
			<div className={"container"}>
			<MainList main={true} total={totalCount} totalTalk={totalTalk}/>
			</div>
		</Layout>
	);
}

export default Main;

export const pageQuery = graphql`
  query { 
  	projects: allMarkdownRemark(
    	filter: {frontmatter: {template: {eq: "project"}}}
    ) {
      totalCount
    }
	talks: allMarkdownRemark(
    	filter: {frontmatter: {template: {eq: "talk"}}}
    ) {
      totalCount
    }
  }
`;

